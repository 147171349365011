.icon{

    width: 20%;
    height: auto;
}

#canvas-container {
    position: absolute; 
    top: 50%;
    right: 0;
    width: 50%;
    height: 50%;

    *{  
        position: relative;
        top: 25%;
        width: 30%;
        height: auto;
        border-radius: 30%;
        animation: moveUpDown 1s ease-in-out infinite
    }



/* Délais d'animation pour créer un effet de décalage */
&>:nth-child(1) {
    animation-delay: 0s;
}

&>:nth-child(2) {
    animation-delay: 0.2s;
}

&>:nth-child(3) {
    animation-delay: 0.4s;
}
}
@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0); 
    }
}

.section__project{

    .icon{
        position: relative;
        top: 30%; 
        width: 12rem;
  
    }

    a{


        height: fit-content;
        text-align: center;
        padding-left: 1%;
        margin-top: 20%;

    }

}

.section__skills{


    .icon{
        position: relative;
        top: 30%; 
        width: 40%;
       
       
  
    }

    .grid_images{

        display: grid;
        grid-template-columns: repeat(3, 300px); /* Crée 2 colonnes égales */
        grid-template-rows: repeat(3, auto); /* Crée 2 lignes automatiques */
        gap: 30px;
        margin-top: -3%;
        align-items: center;
        justify-content: center;
    }

    .icon__php{

        align-self: center;
    }


}
.section__gallery {
    
    position: relative;
    

    .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        
    }

    .gallery-item img {
        width: 400px; 
        height: auto;
        margin-top: 20%;
        border-radius: 5px;
        filter: grayscale(100%); /* Applique l'effet noir et blanc */
        transition: filter 0.3s ease;
        cursor: pointer;
    }


    .gallery-item img:hover {
        filter: grayscale(0%);
    }

    .lightbox {
        display: none;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        padding: 1em;
    
        /* background translucide  */
        background: rgba(0, 0, 0, 0.8);
    }
    
    /* affiche la lightbox quand cliquer */
    .lightbox:target {
        display: block;
    }
    
    .lightbox span {
        display: block;
        width: 70%;
        height: 100%;
        margin: 0 auto;
    
        background-position: center;
        background-repeat: no-repeat;
        background-size:contain;
    }
    
    /* Styles pour l'effet "visité" */
    .thumbnail.visited .thumbnail-image {
        filter: grayscale(0%); /* L'image reste en couleur après avoir été cliquée */
    }

    
}

.section__project--link{



    filter: grayscale(100%); 


}

.section__project--link:hover {
    filter: grayscale(0%);
}

.logo{
    position: fixed;
    top: 2%;
    left: 5%;
    z-index: -1;
    // width: 40%;
    img{
        width: 100px;
        cursor: pointer;
    }

    
}