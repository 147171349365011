@media screen and (max-width: 1023px) {
    .popup__slider {
        display: flex;
        overflow: scroll;
        padding-right: 50px;     
        margin-left: 5%;

        &__el {
            flex: 1 0 50%;
            width: fit-content;
            margin-right: 10%;
            height: fit-content;

            img {
                width: 300px;
                margin: 0;
                left: 0;
            }
        }
    }
}

///////////////////////
/// SLIDER PROJECT ///
/////////////////////

@media screen and (min-width: 1024px) {
    /* Basic slider styles */
    .slider-container {
        position: relative;
        width: 50vw;
        height: 80vh;
        margin: 0 auto;
        overflow: hidden;
        padding: 20px;

        svg {
            position: absolute;
            width: 100vw;
            margin: 0 auto;
            left: -30%;
        }
    }

    .slider {
        display: flex;
        transition: transform 0.5s ease;
        background: transparent;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .description {
            display: block;
            position: absolute;
            right: 0;
            width: 50%;
            top: 50%;
            height: fit-content;
        }
    }
    
    .slider__el {
        min-width: 100%;
        display: none;
    }
    
    .slider__el--show {
        display: block;
    }

    .section__gallery {
        .popup {
            overflow: auto;
            height: 100vh;
        }

        .popup__slider-container {
            top: 0;
            height: 100%;
            width: 30%;
            right: 15%;
            overflow: hidden; 
        }

        .popup__slider {
            display: flex;
            flex-direction: column; /* Modifier la direction de défilement en colonne */
            overflow-y: auto; /* Activer le défilement vertical */
            overflow-x: hidden; /* Désactiver le défilement horizontal */
            padding-bottom: 300px;  
            padding-top: 50px;     
            max-height: 100%;
            box-sizing: border-box; /* Assurer que padding est inclus dans la hauteur totale */

            &__el {
                // display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 10%;
                box-sizing: border-box;

                img {
                    width: 70%;
                    left: 0;
                }

                .desktop_img {
                    width: 100%;
                }
            }
        }

        .adjust_images {
            .popup__slider__el {
                flex-direction: column;
            }
        }

        .popup__info {
            width: 50%;
        }
    }
}

@media screen and (min-width: 1440px) {
    .slider {
        top: 0%;
        transform: translateY(0%);
    }

    .slider__el {
        position: relative;
        top: -100px;
    }
}
