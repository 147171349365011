body {
  background-color: #1b1b1d;
  height: 100%;
  margin: 5%;
  margin-bottom: 2%;
}

body:after {
  content: '';
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/756881/texture.svg);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  pointer-events: none;
  opacity: 0.2;
  filter: invert(1);
  background-size: cover;
}

.popup-open {
  // overflow: hidden;
  // position: fixed;
  margin: 0;
  width: 100vw;
  height: 100vh;

  .menu{

    display: none;
  }
}