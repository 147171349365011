.menu{
    width: 15%;
    margin-right: 5%;

    .menu__liste {
        z-index: 10;
        position: fixed;
        top: 0;
        list-style-type: none;
        justify-content: space-evenly;


        .menu__el {
            a{
                text-decoration: none;

            }
        }
   
    }
}

.nav-link.active {
    font-weight: bold;
    color: #594478;
}

.section{
    background-color: #1b1b1d;

    height: 90vh;
    margin: 0 auto;
    position: sticky;
    top: 0%;
    width: 90vw;


}   
.section__acceuil{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1{

        position: relative;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        

        .mot1, .mot2 {
           
            position: relative;
            width: auto; 
        }
        .mot2{

            
            top: 150px;
        }

    }
    p{

        margin-top: auto;
    
    }
    .paraph--poster{

        position: relative;
        top: -15%;
    }
}

.section__aboutme{


    .title__h2{

        padding-top: 20vh;
    }

    .paraph{

        width: 50%;
    }
}

.section__project{

    .title__h2{

        padding-top: 15vh;
    }

    .paraph{

        width: 50%;
    }

    display: flex;
    justify-content: space-around;
    
}

.section__skills{

    .title__h2{

        padding-top: 20vh;
    }
    
}

.section__contact{

    .title__h2{

        padding-top: 20vh;
    }

}

.section__gallery {

    height: 100%;

    .hidden-content{

        display: none;
    }
    .popup {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;

        .blur {
            backdrop-filter: blur(0.9rem);
            height: auto;
            min-height: 100%;

            width: 100vw;
            position: relative;
            padding-bottom: 20%;


            > * {
                margin-left: 5%;
            }
        }


        &__title {
            text-align: left;
        }

        &__description {
            width: 70%;
        }

        .title {
            top: 0;
            position: relative;
        }

        .fixed {
            position: sticky;
            top: 0;
        }

        &__info {
            width: 90%;
        }

        &__btn {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            width: 95vw;

            svg {
                width: 25%;
                max-width: 60%;
                left: 0;
            }

            h4 {
                width: 260px;
            }

            h4,
            h4 + svg {
                opacity: 40%;
            }
        }

        &__btn--close {
            justify-content: flex-end;
            align-items: center;
            padding-top: 60px;

            .title {
                padding: 0;
                margin-right: 5%;
            }
        }

        .active-content h4,
        .active-content h4 + svg {
            opacity: 100%;
        }

        .active-content h4 + svg {
            width: 50%;
        }
    }


    .title__h2{

        margin-right: 10%;
    }

    .gallery-item--custom.open .hidden-content {
        display: block;
    }
    .gallery-item--custom{

        // border: 2px solid red;

        display: flex;
        flex-direction: column;
        cursor: pointer;

        .title__h2::after{


            content: ""; /* Nécessaire pour afficher un pseudo-élément */
            display: block;
            border-bottom: 2px solid red;
            width: 80vw;
            padding-top: 10%;
        }

        
        
    }
    
    .gallery-item.disabled{
        pointer-events: none; /* Désactive les clics */
        opacity: 0.5;
        .title__h2{
            color: #f0d7d7;
        }

        .title__h2::after{

            border-bottom: 2px solid #f8dcdc;
            
        }
        
    }
    .open{

        .title__h2::after{

            border-bottom: 2px solid #ba9999;
            
        }
    }

    // .rapport--cusstom{

    //     margin: 80px;
    // }
}



.section__contact {
    position: relative;
    height: 90vh;
    
    

    .reseaux{
   
        list-style-type: none;
        
        margin-top: 50px;
        margin-bottom: 0;
        

        &__el{

            width: 35%;
            margin-bottom: 4%;
            a{
                
                display: flex;

                align-items: center;
                flex-direction: row-reverse;

                span{

                    width: 50%;
                    
                }
                
            }
            svg {

                width: 35%;
                fill: #ba9999;

            }
        }



    }  


    
    .reseaux__el a > span {
        margin-left: 15%;
    }

}

@media screen and (min-width: 1024px) {
    
    .section__contact .reseaux {

        margin-top: 50px;

    }

    .section__contact .reseaux__el {

        width: auto;
    
        svg{

            width: 30%;
            

        }
    }

    .grille{

        display: grid;

        grid-template-columns: repeat(2, 400px); /* Crée 2 colonnes égales */
        grid-template-rows: repeat(2, auto); /* Crée 2 lignes automatiques */
        gap: 20px;
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
  

        
    }
}

@media screen and (min-width: 1440px) {
    .section__contact {
    
        .reseaux {

            margin-top: 50px;
            
            .reseaux__el {

                width: 65%;
                margin-bottom: 4%;
    
            }
        }
        

    }
}