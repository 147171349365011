.skill-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.skill-container {
    display: flex;
    align-items: center;
    align-self: end;
}

.skill-name {
    width: 100px;
    font-weight: bold;
    margin-right: 10px;
}

.progress-bar {
    display: flex;
    gap: 5px;
    margin-left: 25%;
}

.square {
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    background-color: #f0f0f0;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.square.filled {
    background-color: rgb(186, 153, 153);
    opacity: 0; 
    transform: scale(0.5); /* Commence plus petit */
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.square.filled.animate {
    opacity: 1; /* Le carré devient visible */
    transform: scale(1); /* Le carré reprend sa taille normale */
}

@keyframes fillSquares {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
.section__project--link{

    
}
.section__acceuil{


    .title__autre{
    
        display: none;
    }
}

.fond {
    background-color: #0e0e10;;
    width: 30px;
    height: 60px;
    margin-top: 1%;
    cursor: pointer;
    position: relative;
    border-radius: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    position: fixed;
    top: 20px; 
    right: 20px;
    z-index: 9999;
  }
  
  .label {
    width: 100%;
    height: 20px;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #FFF;
  }
  
  .fr {
    color: rgba(255, 0, 0, 0.8);
  }
  
  .en {
    color: rgba(0, 0, 255, 0.8);
    margin-top: 15%;
  }
  
  .switch {
    background: #888;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    transition: top 0.2s ease-in-out;
  }
  
  .active .switch {
    top: 33px;
  }
.custom-wrapper--rapport{


    .title--rapport{
    
        margin-top: 21vh;
    }
}

.form__custom{

    &--btn{
  
      background-color: #ba9999;
      color: #ff0000;
    }
    &--input, &--btn{
  
      padding: 20px;
    }
  
}
.color{
    color: #ff0000;
}
@media screen and (min-width: 100px) and (max-width: 1080px) {

    body{

        font-size: 60px;
    }

    .null *, .menu{

        display:none;

    }

    
    .section__acceuil{

        display: flex;
        justify-content: start; 



        .title__autre{
            display: block;            
            
        }
    }
    footer{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
    }
    .body__custom{
        overflow: hidden;
    }




    .form__custom{

        margin-top: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
      
    }
   .custom-wrapper{
       display: flex;
       justify-content: center;
       align-items: center;
       height: 80vh;
       flex-direction: column;
       .title--rapport{
           text-align: center;
        }
    }

    header{

        margin: 0 5%;
    }

}

@media screen and (min-width: 768px) {



}