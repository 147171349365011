@import url("https://fonts.googleapis.com/css2?family=Recursive:slnt,wght,CASL,CRSV,MONO@-15..0,300..1000,0..1,0..1,0..1&display=swap");



@font-face {
    font-family: 'Marvin Visions Variable';
    src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/756881/MarvinVisionsTrial-Variable.ttf");

}

@font-face {
  font-family: 'Marvin Visions';
  src: url('/assets/fonts/MarvinVisionsBig-Bold.eot'); /* pour Internet Explorer */
  src: url('/assets/fonts/MarvinVisionsBig-Bold.woff2') format('woff2'),
       url('/assets/fonts/MarvinVisionsBig-Bold.woff') format('woff'),
       url('/assets/fonts/MarvinVisionsBig-Bold.eot?#iefix') format('embedded-opentype');
  // font-weight: bold;
  font-style: normal;
  font-weight: 100 900; /* Variable font range */
  font-display: swap;
}

@import url("https://p.typekit.net/p.css?s=1&k=cam3uda&ht=tk&f=51594.51596&a=160746579&app=typekit&e=css");
@font-face {
    font-family:"cc-ultimatum-force-variable";
    src:url("https://use.typekit.net/af/09c666/0000000000000000774b979a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/09c666/0000000000000000774b979a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/09c666/0000000000000000774b979a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}


body,.form__custom *{
  font-family: "cc-ultimatum-force-variable";
  font-size: 40px;
  color: rgb(186, 153, 153);

}
a{
    color: #ba9999;
    cursor: pointer;
}

a:hover{

  color: #ff0000;
}
.title{

  font-family: 'Marvin Visions Variable';
  color: hsl(0, 100%, 50%);

}

.work__title{

  margin: 0;
  padding: 0;
}

header {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0vw;
  font-family: "cc-ultimatum-force-variable";
  font-size: 4vh;
  background-color: #1b1b1d;
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;

  
}


h1 {

  font-size: var(--h1-desktop-size);
    font-weight: 170;
    perspective: 400px;
    font-weight: normal; /* Initial font weight */
    color: black; /* Initial text color */
    // animation: changeColor 7s 1 ;
  
}

.title__h1:hover .mot1 span,
.title__h1:hover .mot2 span {
  animation: changeWeight 7s infinite, changeColor 7s infinite;
  animation-fill-mode: forwards; /* Conserve le dernier état de l'animation */
}

.title__h1{

  /* Apply animation to each word with staggered delay */
   span {
    display: inline-block;
    animation: changeWeight 7s 1 , changeColor 7s 1 ;
    animation-fill-mode: forwards; /* Keeps the final state after animation */
  }


}

.section__acceuil{

  p{

    animation: changeWeight2 3s infinite;
    animation-fill-mode: forwards; /* Keeps the final state after animation */
  
  }
}

p:hover{

  animation: changeWeight2 1s infinite;
  animation-fill-mode: forwards; /* Keeps the final state after animation */

}






@keyframes changeWeight2 {
  0% {
      font-variation-settings: 'ital' 0;;
      
  }
  50% {
      font-variation-settings: 'ital' 15;
  }
  100% {
      font-variation-settings: 'ital' 0;;
      
  }
}
@keyframes changeWeight {
  0% {
      font-variation-settings: 'wght' 300;
      
  }
  50% {
      font-variation-settings: 'wght' var(--weight, 700);
  }
  100% {
      font-variation-settings: 'wght' 300;
      
  }
}

@keyframes changeColor {
  0% {
      color: hsl(0, 100%, 50%); /* Bright Red */
  }
  50% {
      color: hsl(347, 7%, 29%);
  }
  100% {
    color: hsl(0, 100%, 50%); /* Bright Red */
  }
}