@import 'utils/variables';
@import 'components/type';
@import 'components/background';
@import 'components/sections';
@import 'components/images';
@import 'components/autre';
@import 'components/footer';
@import 'components/slider';

:root {

    --h1-desktop-size:13vw;
  
    --weight: 10;
   
}